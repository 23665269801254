<template>
    <Button :key="id" :icon="isDisplay ? 'md-eye-off' : 'md-eye'" size="small" @click="onChangeDisplay">{{ isDisplay ? "隐藏" : "显示" }}</Button>
</template>

<script>
export default {
    props: {
        row: Object,
        id: String,
        source: String,
        appId: String,
    },

    data() {
        return {
            isDisplay: false,
        }
    },

    watch: {
        row: {
            handler(v) {
                this.original = v ? JSON.parse(JSON.stringify(v)) : null
                this.isDisplay = false
            },
            immediate: true,
        },
    },

    methods: {
        onChangeDisplay() {
            this.isDisplay = !this.isDisplay

            if (this.isDisplay) {
                this.getOriginalData()
            } else {
                this.$emit("on-change", {
                    tag: "ElemLook",
                    value: this.original,
                })
            }
        },

        getOriginalData() {
            if (this.transformation) {
                return this.$emit("on-change", {
                    tag: "ElemLook",
                    value: this.transformation,
                })
            }

            var url

            switch (this.source) {
                case "report":
                    url = `/syaa/api/syuser/pc/uaUserInfo/findDesensitizationUserById?ubaseId=${this.id}&appId=${this.appId}`
                    break
                case "collect":
                    url = `/gateway/api/fastDev/AppData/findDesensitizationDataById/${this.id}`
                    break
                case "data":
                    url = `/gateway/api/fastDev/AppData/findDataById/${this.id}`
                    break
                default:
                    url = `/syaa/api/syuser/pc/uaUserInfo/selectUserByUbaseId?ubaseId=${this.id}`
                    break
            }

            this.$get(url).then(res => {
                if (res.code != 200) {
                    return this.$Message.error(res.desc)
                }

                var value

                switch (this.source) {
                    case "ubase":
                        value = {}
                        var keys = ["userName", "mobile", "idNum"]

                        for (let i = 0, fps = res.data.formProperties; i < fps.length; i++) {
                            let fp = fps[i]

                            for (let fi = 0, fos = fp.formOperates; fi < fos.length; fi++) {
                                let fo = fos[fi]

                                let idx = keys.indexOf(fo.fieldId)

                                if (idx > -1) {
                                    value[fo.fieldId] = fo.fieldValue
                                    keys.splice(idx, 1)
                                }

                                if (keys.length <= 0) {
                                    break
                                }
                            }

                            if (keys.length <= 0) {
                                break
                            }
                        }
                        break
                    case "data":
                        value = res.data.dataMap
                        break
                    default:
                        value = {
                            id: this.id,
                        }

                        for (let i = 0, l = res.data; i < l.length; i++) {
                            value["key-" + i] = l[i]
                        }
                }

                this.transformation = value

                this.$emit("on-change", {
                    tag: "ElemLook",
                    value: value,
                })
            })
        },
    },
}
</script>

<style></style>
